import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
/**
 * Represents Five Card Component.
 * @author Amresh Yadav
 */import React from 'react';
import Carousel from '../../molecules/Carousel';
import FiftyFiftyCard from '../../molecules/FiftyFiftyCard';
import analyticsConstants from '../../../constants/analyticsConstants';
import { imageRenditions } from './config';
import CarouselArrow from '../../atoms/CarouselArrow';
import styled from 'styled-components';
import styles from './FiveCardNva.style';
var FiveCardBlockNva = styled.section.withConfig({
  componentId: "sc-6yf8h4-0"
})(["", ";"], styles);
var FiveCardNva = function FiveCardNva(_ref) {
  var fiveCardNvaData = _ref.fiveCardNvaData,
    className = _ref.className;
  if (!fiveCardNvaData) {
    return null;
  }
  var Settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    infinite: false,
    dots: true,
    prevArrow: __jsx(CarouselArrow, {
      type: "previous",
      analyticsType: analyticsConstants.imageSlider.analyticsType.ARROWS,
      analyticsValue: analyticsConstants.imageSlider.analyticsValue.PREV_ARROW
    }),
    nextArrow: __jsx(CarouselArrow, {
      type: "next",
      analyticsType: analyticsConstants.imageSlider.analyticsType.ARROWS,
      analyticsValue: analyticsConstants.imageSlider.analyticsValue.NEXT_ARROW
    })
  };
  var cards = fiveCardNvaData && fiveCardNvaData.storyItemsCollection && fiveCardNvaData.storyItemsCollection.items && fiveCardNvaData.storyItemsCollection.items.length ? fiveCardNvaData.storyItemsCollection.items : [];

  /**
   * Ģ��tv-410: Fixing regression of partial refactoring of graphQL updates.
   * It has to support both structures.
   **/
  if (cards.length === 0 && fiveCardNvaData && fiveCardNvaData.cards) {
    cards = fiveCardNvaData.cards;
  }
  var variationClass = "variation-a";
  var isStoryBlade = fiveCardNvaData && fiveCardNvaData.storyItemsCollection ? true : false;
  var anchorLink = fiveCardNvaData && (fiveCardNvaData === null || fiveCardNvaData === void 0 ? void 0 : fiveCardNvaData.anchorLink) || '';
  return __jsx(FiveCardBlockNva, {
    className: "nva-5-card-nva organism five-card-nva ".concat(className),
    id: anchorLink
  }, __jsx(Carousel, _extends({
    className: variationClass,
    classes: {
      carousel: 'nva-5-card-nva__carousel',
      allTilesContainer: 'nva-5-card-nva__all-tiles-container'
    },
    styleType: "heroCarousel",
    slidesToShow: 1,
    autoplay: false,
    responsive: Settings.responsive
  }, Settings), cards.map(function (carddata, index) {
    var _carddata$cta, _carddata$ctaSecond;
    var shortText = carddata.shortText ? carddata.shortText.json : null;
    //TODO: Remove once all queries have been refactored
    if (!shortText) {
      shortText = carddata.shortText ? carddata.shortText.shortText : null;
    }
    var imageSource = carddata.bladeBackgroundImage && carddata.bladeBackgroundImage.image && carddata.bladeBackgroundImage.image.url;
    //TODO: Remove once all queries have been refactored
    if (shortText === null) {
      imageSource = carddata.bladeBackgroundImage && carddata.bladeBackgroundImage.image && carddata.bladeBackgroundImage.image.file && carddata.bladeBackgroundImage.image.file.url;
    }
    var cta = [];
    if (carddata !== null && carddata !== void 0 && (_carddata$cta = carddata.cta) !== null && _carddata$cta !== void 0 && _carddata$cta.url) {
      cta.push({
        url: "".concat(carddata.cta.url),
        label: "".concat(carddata.cta.label),
        behavior: "".concat(carddata.cta.behavior)
      });
    }
    if (carddata !== null && carddata !== void 0 && (_carddata$ctaSecond = carddata.ctaSecond) !== null && _carddata$ctaSecond !== void 0 && _carddata$ctaSecond.url) {
      cta.push({
        url: "".concat(carddata.ctaSecond.url),
        label: "".concat(carddata.ctaSecond.label),
        behavior: "".concat(carddata.ctaSecond.behavior)
      });
    }
    var dto = {
      className: "nva-5-card-nva__50-50-card fifty-fifty-card row ".concat(carddata.variation),
      ariaLabel: cta ? cta.label : null,
      fontColor: carddata.fontColor,
      headingText: carddata.headline,
      sideHeadline: carddata.sideHeadline,
      subHeadingText: carddata.subHeadline,
      overrideBackground: fiveCardNvaData.background,
      descriptionText: shortText,
      imageSource: "".concat(imageSource),
      placeholderSrc: "".concat(imageSource),
      media: {
        image: carddata.bladeBackgroundImage,
        video: carddata.video || ''
      },
      alt: carddata.headline,
      cta: cta,
      styleTypeAnchor: 'primary-anchor',
      styleType: 'fiftyFifty',
      classNameHeading: 'nva-5-card-nva__50-50-heading fiftyFiftyHeading',
      classNameSubHeading: 'nva-5-card-nva__50-50-sub-heading fiftyFiftySubHeading',
      classNamePara: 'nva-5-card-nva__50-50-para fiftyFiftyPara',
      classNameAnchor: 'nva-5-card-nva__50-50-anchor fiftyFiftyAnchor  cta_btn btn',
      dataAnalytics: {
        type: analyticsConstants.nva6040Carousel.analyticsType.CTA_6040,
        value: carddata.headline,
        variation: carddata.variation
      },
      srcSetSetting: {
        imageRenditions: imageRenditions
      }
    };
    return __jsx(FiftyFiftyCard, _extends({
      key: index,
      isStoryBlade: isStoryBlade
    }, dto));
  })));
};
FiveCardNva.defaultProps = {
  className: ''
};
export default styled(FiveCardNva).withConfig({
  componentId: "sc-6yf8h4-1"
})(["", ";"], styles);